<template>
    <div id="app-lesson" class="app-add">
      <loading :loading="loading" text="提交中"></loading>
        <el-form :model="lesson" label-width="100px">
            <el-form-item label="课时名称" prop="name">
                <el-input v-model="lesson.name" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item label="课时类型" prop="type">
                <el-radio-group v-model="lesson.type" @change="radioTypeChange">
                    <el-radio :label="0">点播</el-radio>
                    <el-radio :label="1">直播</el-radio>
                    <el-radio :label="2">直播补录</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="直播类型" prop="isPushLive" v-show="isShowLiveType">
                <el-radio v-model="lesson.isPushLive" :label="false">常规直播</el-radio>
                <el-radio v-model="lesson.isPushLive" :label="true">推流直播</el-radio>
            </el-form-item>

            <el-form-item label="上课时间" v-show="isShowDateTime">
                <el-date-picker
                        v-model="dateTime"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="视频ID" v-show="isShowVideoId">
                <el-input v-model="lesson.videoId" placeholder="百家云视频ID"></el-input>
            </el-form-item>

            <el-form-item label="选择讲师">
                <el-radio v-for="teacher in teachers" v-model="lesson.teacherId" :label="teacher.id">
                    {{teacher.name}}
                </el-radio>
            </el-form-item>

            <el-form-item label="是否试听">
                <el-radio v-model="lesson.isFree" :label="false">否</el-radio>
                <el-radio v-model="lesson.isFree" :label="true">是</el-radio>
            </el-form-item>

            <el-form-item style="display: none">
                <el-button @click="submit('form')" id="layuiadmin-app-form-submit">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {UrlEnum,TipEnum} from "../../../public/js/common-vue";
    import {LessonTypeEnum} from "../../../public/js/common-vue";
    import {getLocalProjectId} from "../../../public/js/common-vue";
    import Loading from "@/components/Loading";

    const LOCALSTORAGE_TEACHER_KEY = "LESSON_TEACHER_DEFAULT_ID";

    export default {
      components: {Loading},
      data() {
            return {
                lesson:{
                    name: '',
                    type: 0,
                    isPushLive: false,
                    startTime:new Date(),
                    endTime:new Date(),
                    videoId:0,
                    teacherId: 0,
                    projectId: getLocalProjectId(),
                    isFree: false
                },
                loading: false,
                isShowLiveType:false,
                isShowDateTime:false,
                isShowVideoId:true,
                teachers:[],
                dateTime: [new Date(), new Date()]
            }
        },
        methods: {
            getTeachers:function(){
                this.$http({
                    method: "get",
                    url: UrlEnum.LESSON_TEACHERS,
                }).then((res) => {
                    this.teachers = res.data.list;
                    this.lesson.teacherId = localStorage.getItem(LOCALSTORAGE_TEACHER_KEY);
                    if(this.lesson.teacherId == null || this.lesson.teacherId == 'undefined'){
                        this.lesson.teacherId = res.data.list[0].id;
                    }
                    this.lesson.teacherId = parseInt(this.lesson.teacherId);
                }).catch((res) => {
                    if(res.response != null){
                        const data = res.response.data;
                        this.showMsgError(data.message);
                    }
                });
            },
            radioTypeChange:function(){
                var type = this.lesson.type;
                if(type == LessonTypeEnum.VOD){
                    this.isShowDateTime=false;
                    this.isShowVideoId=true;
                    this.isShowLiveType=false;
                }
                else if(type == LessonTypeEnum.LIVE){
                    this.isShowDateTime=true;
                    this.isShowVideoId=false;
                    this.isShowLiveType=true;
                }
                else if(type == LessonTypeEnum.LIVE_SUPPLY){
                    this.isShowDateTime=true;
                    this.isShowVideoId=true;
                    this.isShowLiveType=false;
                }
            },
            submit: function(){
                if(this.lesson.name == ""){
                    this.showMsgError("请输入 课时名称");
                    return;
                }
                if(this.dateTime == null){
                    this.showMsgError("请选择 开始/结束时间");
                    return;
                }
                localStorage.setItem(LOCALSTORAGE_TEACHER_KEY,this.lesson.teacherId); //保存默认选中的老师下次继续使用
                this.lesson.startTime = this.moment(this.dateTime[0]).format("YYYY-MM-DD HH:mm:ss");
                this.lesson.endTime = this.moment(this.dateTime[1]).format("YYYY-MM-DD HH:mm:ss");
                if(this.lesson.type == LessonTypeEnum.LIVE || this.lesson.type == LessonTypeEnum.LIVE_SUPPLY){
                    //验证上课时间是否合法
                    if(this.lesson.startTime >= this.lesson.endTime){
                        this.showMsgError("起始时间-结束时间 设置错误");
                        return;
                    }
                }
                console.log(JSON.stringify(this.lesson));
                this.loading=true;
                this.$http({
                    method: "post",
                    url: UrlEnum.LESSON,
                    data: this.lesson,
                })
                .then((res) => {
                  this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
                  setTimeout(function () {
                    window.parent.postMessage({
                      msg: '',
                      key: 'refresh'
                    }, '*')
                  }, 800);
                })
                .catch((res) => {
                    this.loading=false;
                    if(res.response != null){
                        const data = res.response.data;
                        this.showMsgError(data.message);
                    }
                });
            }
        },
        mounted() {
            this.getTeachers();
        },
    }
</script>

<style scoped>
    @import "../../../static/css/add.css";
</style>
